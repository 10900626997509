import React from "react";

export default function Footer() {
  return (
    <footer className="footer bg-white">
      <div className="container mx-auto py-6">
        <p className="text-sm text-gray-500 text-center mb-2">
          &copy; All Rights Reserved by RentSpree, Inc.
        </p>
      </div>
    </footer>
  );
}
