import React from "react";
import Link from "next/link";
import styled from "@emotion/styled";
import xw from "xwind";

const MenuButton = styled.a(
  xw`cursor-pointer text-base font-medium text-white p-4 hover:text-white hover:bg-blue-600`
);

const Navbar = ({ user, loading }) => {
  return (
    <div className="relative">
      <div className="relative bg-white">
        <div className="mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link href="/">
                <a>
                  <span className="sr-only">Demo</span>
                  <img
                    className="h-12"
                    src="https://cdn.rentspree.com/static-files/logos/demo-logo.png"
                    alt="Demo"
                  />
                </a>
              </Link>
            </div>
            {!loading &&
              (user ? (
                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                  <Link href="/api/logout">
                    <a className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                      Logout
                    </a>
                  </Link>
                </div>
              ) : (
                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                  <Link href="/api/login">
                    <a className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                      Sign in
                    </a>
                  </Link>
                  <Link href="/api/login">
                    <a className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                      Sign up
                    </a>
                  </Link>
                </div>
              ))}
            
          </div>
        </div>
      </div>
      <div className="bg-blue-900">
        <div className="mx-auto px-4 sm:px-6">
          <nav className="flex">
            <Link href="/">
              <MenuButton>
                <div className="w-5 inline-block mr-2 -mb-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </div>
                Home
              </MenuButton>
            </Link>
            {!loading &&
              (user ? (
                <>
                  <Link href="/dashboard">
                    <MenuButton>
                    <div className="w-5 inline-block mr-2 -mb-1">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                      </svg>
                    </div>
                      Dashboard
                    </MenuButton>
                  </Link>
                  <Link href="/tenant-screening">
                    <MenuButton>
                      <div className="w-5 inline-block mr-2 -mb-1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                          <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                        </svg>
                      </div>
                      Tenant Screening
                    </MenuButton>
                  </Link>
                  <Link href="/mls">
                    <MenuButton>
                      <div className="w-5 inline-block mr-2 -mb-1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                        </svg>
                      </div>
                      MLS
                    </MenuButton>
                  </Link>
                </>
              ) : null)}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
