import Head from "next/head";
import { IntlProvider } from "react-intl";
import { RecoilRoot } from "recoil";

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { useFetchUser } from "../hooks/user";

import "../styles/globals.css";

function App({ Component, pageProps }) {
  const { user, loading } = useFetchUser({ required: false });

  return (
    <>
      <Head>
        <title>RentSpree Demo Application</title>
      </Head>
      <RecoilRoot>
        <IntlProvider locale="en" defaultLocale="en">
          <div className="flex flex-col min-h-screen">
            <Navbar user={user} loading={loading} />
            <Component {...pageProps} />
            <Footer />
          </div>
        </IntlProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
